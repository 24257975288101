.senderBubble{
    border-top-left-radius: 24px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 24px;
    border-bottom-left-radius: 24px;
}
.botBubble{
    border-top-left-radius: 8px;
    border-top-right-radius: 24px;
    border-bottom-right-radius: 24px;
    border-bottom-left-radius: 24px;
}
.botBubble :global(.wmde-markdown),
.senderBubble :global(.wmde-markdown){
    background: transparent;
    font-family: inherit;
    font-size: 14px;
    color: inherit;
    overflow-x: auto;
}
.botBubble :global(.ant-card-actions){
    position: absolute;
    bottom: -42px;
    right: 0;
    left: 0;
    opacity: 0;
    transform: translate3d(0, 100%, 0);
    z-index: 2;
    pointer-events: none;
    transition: opacity 0.3s, transform 0.3s;
}
.botBubble:hover :global(.ant-card-actions){
    opacity: 1;
    transform: translate3d(0, 0, 0);
    pointer-events: all;
}