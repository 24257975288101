.researchCard{
    text-align: center;
}
.researchCard :global(.ant-card-body){
    padding: 0 !important;
}
.researchCardContent{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 24px;
}
.researchCardLink{
    display: flex;
    padding: 24px;
    justify-content: center;
}
.researchCardTitleBox{
    max-width: 100%;
    overflow: hidden;
}
.researchCardTimestamp{
    font-size: 12px;
    margin: 0;
}