@keyframes fadeIn {
    from{
        opacity: 0;
    }
    to{
        opacity: 1;
    }
}
.loader{
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.loaderSpinner{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 300px;
    height: 150px;
    overflow: visible;
}
.loaderText{
    display: flex;
    flex-direction: column;
}
.loaderLottie{
    animation: fadeIn ease-out .2s
}