.chatBox{
    display: flex;
    padding: 0 16px;
}
:global(.chat .ant-card-body){
    max-height: 200px !important;
    overflow: auto;
}
.dashboard{
    display: flex;
    flex-direction: column;
    gap: 16px;
    position: relative;
    flex-grow: 1;
}
.dashboardContent{
    position: relative;
    overflow: auto;
}
.dashboardWidgets{
    flex-grow: 1;
    padding-bottom: 24px;
}
.assetsWidget{
    height: calc(100vh - 48px);
    position: sticky;
    top: 0;
}
.insightWidgets{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.sentimentButtons{
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: left;
    gap: 8px;
}
.sentimentWidget{
    height: 100%;
}
.sentimentWidget .ant-card-body{
    height: 100%;
}
.sentimentButton{
    display: grid;
    grid-template-columns: 24px 1fr 24px;
    gap: 16px;
    align-items: center;
}
.sentimentButtonLabel{
    margin: 0;
}
.personaCard h2,
.personaCard h3,
.personaCard p{
    margin: 0;
}
.uploadArea{
    border-radius: 8px;
}
.formatIcon{
    height: 42px;
}
.integrationIcon{
    height: 128px;
}
.ant-layout:has(.dashboard) :global(.ant-layout-sider){
    display: none !important;
}

.personaDetails{
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
}

.personaInfo{
    flex: 1 0 200px;
    align-items: start;

    display: flex;
    justify-content: start;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    border-radius: 16px;
    padding: 12px;
}
.reportContainer{
    display: flex;
    flex-direction: column;
    align-items: center;  
    justify-content: center;
    width: 100%;
}
.reportContent{
    max-width: 1200px;
}
.competitorDescriptionCol{
    max-width: 500px;
}
.formatIcon{
    height: 42px;
}
.breakLines{
    white-space: pre-line;
}
.exportModal :global(.ant-modal-content),
.exportModal :global(.ant-modal-title){
    background-color: #f3f4f5;
}
@media screen and (max-width: 1600px){
    .reportContent{
        max-width: 90%;
    }
}
@media screen and (max-width: 1440px){
    .reportContent{
        max-width: 100%;
    }
}
@media screen and (max-width: 1023px){
    .personaInfo{
        width: 100%;
    }
    .reportContent{
        max-width: 100%;
        margin: 0;
    }
}

@media print {
    .reportContent{
        width: 595px;
        font-size: 12px;
    }
}

.uploadButton{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 8px 16px;
    border-radius: 16px;
    color: var(--ant-color-primary);
    background-color: var(--ant-color-primary-bg);  
    cursor: pointer;
    height: 100%;
    transition: ease-out 0.2s;
    border: 1px dashed var(--ant-color-primary-border);
}
.uploadButton:hover{
    background-color: var(--ant-color-primary-bg-hover);
    border-color: var(--ant-color-primary-border-hover);
}
.uploadButton:active{
    opacity: 0.8;
}