.footer{
    display: flex;
    gap: 24px;
    flex-wrap: wrap;
    padding: 24px 0;
    border-top: 1px solid rgba(150, 150, 150, 0.2);
    justify-content: center;
}
.footerLink{
    padding: 12px 0;
}
@media screen and (max-width: 1023px){
    .footer{
        display: none;
    }
}
:global(.researchPage) .footer{
    display: none;
}
:global(.sharePage) .footer{
    display: none;
}