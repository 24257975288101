@tailwind base;
@tailwind components;
@tailwind utilities;


.App {
  height: 100%;
  flex-wrap: wrap;
}

blockquote, dd, dl, figure, h1, h2, h3, h4, h5, h6, hr, p, pre{
  font-size: revert;
  font-weight: revert;
  margin: revert;
  line-height: revert;

}
.logo{
  display: flex;
  padding: 8px;
  margin-bottom: 24px;
  width: 100%;
  margin-top: 16px;
}
.logo img{
  max-width: 100%;
}
html, body{
  height: 100%;
  overflow: hidden;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.ant-layout-footer{
  flex: 1 0 100%;
}

@media screen and (min-width: 1024px){

  .ant-menu{
    padding-right: 16px;
    background: transparent !important;
    box-shadow: none !important;
  }
    .ant-drawer-right>.ant-drawer-content-wrapper {
        top: 24px;
        bottom: 24px;
        right: 24px;
        border-radius: 24px;
        overflow: hidden;
    }
    
    .chatWidgetDrawer button.ant-drawer-close {
        top: 32px;
        left: 24px;
    }
}
.ant-alert.ant-alert-banner{
  display: flex !important;
}
.InputContainer{
  padding: 16px 24px;
  border: 1px solid rgba(40, 44, 52, 0.1);
}
.App-link {
  color: #61dafb;
}

.ant-modal .wmde-markdown{
  width: 100%;
}
.ant-modal .ant-modal-content{
  max-width: 100%;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.ant-drawer-body .ant-list-item{
  align-items: start;
}
@keyframes slideFadeIn{
  from{
    opacity: 0;
    transform: translate3d(0px, 0px, 0px) scale(0.95);
  }
  to{
    opacity: 1;
    transform: translate3d(0px, 0px, 0px) scale(1);
  }
}
.mainContent{
  padding: 0 24px 24px 24px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
}

.ant-menu{
  background: transparent;
  width: 100%;
}
.ant-layout-sider-children{
  padding: 32px 16px;

}
.ant-layout-sider{
  width: 300px;
  background-color: transparent !important;
}

.ant-tabs-nav .ant-tabs-nav-wrap{
  overflow: visible !important;
}
.ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list{
  border-radius: 32px;
  padding: 6px;
}
p{
  margin: 0;
}
.ant-divider{
  margin: 12px 0;
}
.ant-tabs-nav:before{
  display: none;
}
.ant-tabs-nav-list .ant-tabs-tab{
  border-radius: 24px !important;
  border-bottom-color: #f0f0f0 !important;
  padding: 12px 32px !important;
}
.ant-tabs-nav-list .ant-tabs-tab:not(.ant-tabs-tab-active){
  background-color: transparent;
}
.ant-tabs-nav-list .ant-tabs-tab.ant-tabs-tab-active{
  font-weight: 600;
  box-shadow: 0px 2px 4px rgba(107, 79, 72, 0.12);
}
.ant-upload-wrapper .ant-upload-drag, .ant-upload-btn{
  max-height:min-content;
  height: auto;
}
.mermaid{
  display: flex;
  justify-content: center;
}

.stickyNote{
  border-radius: 8px;
  border-top-right-radius: 32px;
  height: 100%;
  display: flex;
  flex-direction: column;
  animation: slideFadeIn .2s ease-in-out;
}

.stickyNote .stickyNoteFold{
  content:' ';
  position: absolute;
  width: 32px;
  transform-origin: top right;
  transition: ease-in .2s;
  height: 28px;
  top: 0;
  right: 0;
  border-top-right-radius: 32px;
}
.stickyNote .ant-card-body {
  display:flex;
  flex-direction:column;
  flex-grow:1;
  justify-content: start;
  align-items: flex-start;
  /* flex-grow: 1; */
  height: 100%;
}
.stickyNote .ant-card-head{
  min-height: 0;
  padding-top: 16px
}
.stickyNote .ant-card-head + .ant-card-body > p{
  padding-right: 0;
}
.stickyNote .ant-card-body > p{
  flex-grow:1;
  /* flex: 1 0 100%; */
  display: flex;
  flex-direction: column;
  padding-right: 32px;
}
.stickyNote .ant-card-actions{
  opacity: 0;
  transition: ease-out .15s;
  transform: translate3d(0px, 100%, 0px);
}
.stickyNote:has(textarea) .ant-card-actions{
  opacity: 1;
  transition: ease-out .15s;
  transform: translate3d(0px, 0px, 0px);
}
.stickyNote:hover .ant-card-actions{
  opacity: 1;
  transform: translate3d(0px, 0px, 0px);
}

.stickyButton{
  cursor: pointer;
  user-select: none;
  color: #db9c0a;
  padding: 0;
  transition: ease-out .15s;
  text-align: center;
  display: flex;
  justify-content: center;
}
.stickyButton .ant-card-body{
  justify-content: center;
  align-items: center;
}
.stickyButton .ant-card-body p{
  flex-grow: 0;
  font-weight: 600;
  padding-right: 0;
}
.stickyButton:hover{
  color:#cc9900;
  background-color: rgba(255, 229, 143, 0.35);
  border-top-right-radius: 12px;
}
.stickyButton:hover .stickyNoteFold{
  transform: scale(0);
  background-color: #fae48e !important;
}
.mobileOnly{
  display: none;
}
.ant-card h5{
  margin: 0;
}
.chatWidgetCol .ant-card{
  overflow: hidden;
}
.ant-tag{
  align-self: start;
}
.upgrade-button{
  color: #5e28d1;
  font-weight: 700;
  border: 1px solid rgba(150,150,150, 0.2);
  margin-bottom: 24px !important;
  background-color: #FFFFFF;
  box-shadow: 0px 2px 12px rgba(60, 10, 30, 0.12);
}
.upgrade-button.dark{
  color: #7b50d5;
  box-shadow: 0px 3px 9px rgba(20, 0, 5, 0.6);

}
.rotate90{
  transform: rotate(90deg);
  display: flex;
  width: 180px;
  height: 180px;
  justify-content: center;
  align-items: center;
  line-height: 1;
  padding-top: 16px !important;
}
@media screen and (max-width: 1023px){
  .logo{
      display:none;
  }
  .stickyNote{
    min-height: 128px;
  }
  .ant-alert.ant-alert-banner{
    display: flex !important;
    white-space: break-spaces;
    align-items: center;
    font-weight: 500;
    width: 100%;
    gap: 8px;
    flex-wrap: wrap;
  }
  .ant-alert.ant-alert-banner .ant-alert-content{
    flex: 1 0 100%;
    padding-left: 24px;
  }
  .ant-alert.ant-alert-banner > .anticon{
    position: absolute;
    top: 12px;
    left: 16px;
  }
  .upgrade-button{
    display: none !important;
    margin-bottom: 0 !important;
  }
  .mobileOnly{
    display: flex;
  }


  .chatWidgetCol .ant-card-body{
    height: calc(100% - 128px) !important;
  }
  
  .ant-modal{
    height: calc(100% - 32px) !important;
    max-height: calc(100% - 32px) !important;
    top: 24px;
  }
  .ant-layout-sider{
      position: fixed !important;
      z-index:44;
      display: flex;
      justify-content: center;
      min-width: 100% !important;
      align-items:center;
      padding: 0;
      bottom: 0;
      left:0;
  }
  .ant-layout-sider-children{
      padding-bottom: 0;
      width: 100%;
  }
  .ant-layout-sider .ant-layout-sider-children .ant-menu{
      display:flex;
      align-items:center;
      justify-content: space-around;
      gap: 8px;
      padding: 24px 0 12px;
      border-radius: 24px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
  }
  .ant-layout-sider .ant-menu-item{
      display: flex;
      justify-content:center;
      align-items: center;
      padding: 0 !important;
      width: 42px;
      height: 42px;
  }
  .ant-layout-sider .ant-menu-item svg{
      width: 20px;
      height: 20px;
  }

  .ant-layout-sider .ant-menu-item .ant-menu-title-content{
      display: none;
  }
  h1.ant-typography{
      font-size: 24px;
      font-weight: 800;
  }
  .mainContent{
    position: relative;
    padding-bottom: 128px;
  }
  .ant-tabs-nav-list .ant-tabs-tab{
    border-radius: 24px !important;
    border-bottom-color: #f0f0f0 !important;
    padding: 8px 16px !important;
  }
  .ant-drawer-content-wrapper{
    width: 100% !important;
  }

  .ant-tabs-nav{
    overflow-x: auto;
  }
  .ant-tabs-mobile{
    height: auto !important;
    padding-bottom: 96px;
  }
  .researchPage .ant-layout-sider{
    display: none;
  }
}


.chatWidgetDrawer .ant-card-head{
  padding-right: 16px;
  padding-left: 48px;
  z-index: 8;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: var(--ant-card-actions-bg);
}
.chatWidgetDrawer .ant-drawer-body > .ant-card{
  border-radius: 0;
  padding-top: 56px;
}
.chatWidgetDrawer button.ant-drawer-close {
  position: absolute;
  top: 8px;
  left: 8px;
  z-index: 12;
  width: 42px;
  height: 42px;
}

.chatWidgetDrawer .ant-drawer-header {
    position: absolute;
    z-index: 9;
    top: 0;
    border: none;
    left: 0;
    width: 42px;
    height: 42px;
}
.chatWidgetDrawer .ant-card-actions{
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
.chatWidgetDrawer .ant-drawer-body{
  padding: 0;
}
.chatWidgetDrawer > .ant-drawer-wrapper-body > .ant-drawer-body > .ant-card{
  display: flex;
  flex-direction: column;
  min-height: 100% !important; 
}
.chatWidgetDrawer > .ant-drawer-wrapper-body > .ant-drawer-body  > .ant-card > .ant-card-body{
  min-height: 100% !important; 
  height: 100% !important; 
  flex-grow: 1;
}
a:focus{
  box-shadow: 0px 0px 0px 4px rgba(94, 40, 209, 0.3)
}
.cc-bg-black\/30{
  z-index:12;
}
.cc-p-\[25px\]{
  z-index: 13;
}
.cc-flex .cc-overflow-y-auto h1{
  font-size: 16px;
}
.cc-flex .cc-flex .cc-h-full:has(.cc-overflow-y-auto){
  height: calc(100% - 54px) !important;
}
svg{
  vertical-align: unset;
}
.ant-btn.ant-btn-default{
  border-color: var(--ant-color-primary-border);
  color: var(--ant-color-primary)
}
.ant-btn.ant-btn-default:hover{
  background-color: var(--ant-color-primary-bg);
}
.ant-btn.ant-btn-default.ant-btn-dangerous{
  border-color: var(--ant-color-error-border);
  color: var(--ant-color-error)
}
.ant-btn.ant-btn-default.ant-btn-dangerous:hover{
  background-color: var(--ant-color-error-bg);
}
.ant-btn.ant-btn-primary{
  background-color: var(--ant-color-primary);
}
.ant-btn.ant-btn-primary.ant-btn-dangerous{
  background-color:var(--ant-color-error);
}
button.ant-switch{
  background-color: #aeaeae;
}
.ant-btn.ant-btn-primary:disabled{
  background-color: #5e28d1;
  opacity: 0.5;
  color: #FFFFFF;
}
.ant-spin{
 opacity: 1;
 z-index: 12;
 background-color: #FFFFFF;
}

/* Carbon Component minor style fixes */
.cc-flex h1{
  font-size: 20px;
}
.cc-flex .cc-text-lg{
  margin: 0;
}
ul, ol{
  padding-left: 16px;
  list-style: initial;
}
.cc-flex ul{
  list-style: none;
  padding-left: 0;
}
.cc-flex ul h1{
  font-size: 14px;
}

.ant-carousel .slick-prev,
.ant-carousel .slick-next,
.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover,
.ant-carousel .slick-prev:focus,
.ant-carousel .slick-next:focus {
  font-size: inherit;
  color: white !important;
  position: absolute;
  z-index: 4;
}
.ant-carousel .slick-dots-bottom {
  bottom: 80px !important;
  z-index: 24;
  padding: 16px 24px;
  background: #ffffff;
  width: fit-content;
  left: 0;
  right: 0;
  margin: auto;
  border-radius: 24px;
  box-shadow: 0px 4px 12px rgba(0,0,0,0.12);
}
.ant-carousel .slick-dots li button{
  background-color: rgba(39, 33, 54, 0.5);
}
.ant-carousel .slick-dots li.slick-active button{
  background-color: rgb(85, 79, 103);
}
.ant-carousel .slick-prev {
  left: 42px;
}
.ant-carousel .slick-next {
  right: 42px;
}
.ant-carousel .slick-prev::before {
  color: white !important;
}

.slick-arrow.slick-prev {
  font-size: 20px !important;
}

.ant-carousel .slick-next::before {
  color: white !important;
}

.slick-arrow.slick-next {
  font-size: 20px !important;
}
.ant-alert + .ant-layout{
  height: calc(100% - 49px) !important;
}

.ant-upload{
  border-radius: 8px !important;
}

.trialAlert{
  padding: 12px 24px;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
}
.trialAlert .ant-alert-content{
  flex: initial;
}
.trialAlert .anticon{
  margin-right: 0;
}
@media screen and (max-width: 1023px){
  .desktopOnly{
    display: none;
  }
}

div#header-bar {
  display: none;
}
/* Temporary fix - needs improvement */

.ant-tooltip .ant-tooltip-content .ant-tooltip-inner {

  border-radius: 8px;
  padding: 8px 16px;
}
.textHighlightActive{
  background-color: var(--ant-color-warning);
  
}

::-moz-selection { /* Code for Firefox */
  color: #FFFFFF;
  background: var(--ant-color-primary);
}

::selection {
  color: #FFFFFF;
  background: var(--ant-color-primary);
}
.cc-flex .absolute svg{
  margin: 8px;
}

.rs-csv-viewer .ant-table-cell{
  white-space: nowrap;
}