.loginForm{
    flex-direction: column;
    height: 100%;

    gap: 42px;
    background: #fcfaff;
}
.logoWrapper{
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
}
.logoWrapper h2{
    font-size: 64px;
    font-weight: 300;
    margin: 0;
    text-align: center;
    margin-bottom: 24px;
    background-image: radial-gradient(63.4% 150% at 19% 18%, rgb(232, 162, 244) 0%, rgb(95, 92, 226) 57.6577%, rgb(79, 34, 223) 100%);
    background-clip: text;
    color: transparent;
}

.logo img{
    height: 38px;
}

.googleLogin{
    padding: 16px 32px;
    border-radius: 24px;
    gap: 16px;
    display: flex;
    align-items: center;
    border: none;
    cursor: pointer;
    outline: none;
    color:#FFFFFF;
    transition: ease-out .2s;
    background: radial-gradient(63.4% 150% at 19% 18%, rgb(232, 162, 244) 0%, rgb(95, 92, 226) 57.6577%, rgb(79, 34, 223) 100%);
}
.loginDisclaimer{
    padding: 0 16px;
}
.googleLogin:hover{
    opacity: 0.85;
}
.googleLogin:active{
    opacity: 0.7;
}
.googleLogin[disabled]{
    background: #e0e0e0;
    color: #9e9e9e;
    cursor: not-allowed;
}
.loginLink{
    color: transparent;
    background-image: radial-gradient(63.4% 150% at 19% 18%, rgb(232, 162, 244) 0%, rgb(95, 92, 226) 57.6577%, rgb(79, 34, 223) 100%);
    background-clip: text;
    text-decoration: underline;
    font-weight: 600;
    border-bottom: 1px solid transparent;
}
.loginLink:hover{
    background-image: radial-gradient(63.4% 150% at 19% 18%, rgb(232, 162, 244) 0%, rgb(95, 92, 226) 57.6577%, rgb(79, 34, 223) 100%);
    opacity: 0.85;
    border-color: #4f22df;
}
.loginLink:active{
    background-image: radial-gradient(63.4% 150% at 19% 18%, rgb(232, 162, 244) 0%, rgb(95, 92, 226) 57.6577%, rgb(79, 34, 223) 100%);
    opacity: 0.7;
}
@media screen and (max-width: 780px){
    .logoWrapper h2{
        font-size: 32px;
        font-weight: 600;
    }
 
    .logo img{
        height: 28px;
    }
}