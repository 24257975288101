.chatBox{
    display: flex;
    padding: 0 16px;
}
:global(.chat .ant-card-body){
    max-height: 200px !important;
    overflow: auto;
}
.dashboard{
    display: flex;
    flex-direction: column;
    gap: 16px;
    position: relative;
    flex-grow: 1;
}
.dashboardContent{
    position: relative;
    overflow: auto;
}
.dashboardWidgets{
    flex-grow: 1;
    padding-bottom: 24px;
}
.assetsWidget{
    height: calc(100vh - 48px);
    position: sticky;
    top: 0;
}
.insightWidgets{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.sentimentButtons{
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: left;
    gap: 8px;
}
.sentimentWidget{
    height: 100%;
}
.sentimentWidget .ant-card-body{
    height: 100%;
}
.sentimentButton{
    display: grid;
    grid-template-columns: 24px 1fr 24px;
    gap: 16px;
    align-items: center;
}
.sentimentButtonLabel{
    margin: 0;
}
.personaCard h2,
.personaCard h3,
.personaCard p{
    margin: 0;
}


.ant-layout:has(.dashboard) :global(.ant-layout-sider){
    display: none !important;
}

.personaDetails{
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
}

.personaInfo{
    flex: 1 0 200px;
    align-items: start;

    display: flex;
    justify-content: start;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    background-color: rgba(150,150,150, 0.1);
    border-radius: 16px;
    padding: 12px;
}
.reportContainer{
    display: flex;
    flex-direction: column;
    align-items: center;  
    justify-content: center;
    width: 100%;
}
.reportContent{
    max-width: 1200px;
}
.competitorDescriptionCol{
    max-width: 500px;
}

.breakLines{
    white-space: pre-line;
}
.exportModal :global(.ant-modal-content),
.exportModal :global(.ant-modal-title){
    background-color: #f3f4f5;
}

.widgetsPanel{
   width: 100%;
    border-radius: 16px;
    background: var(--ant-color-bg-container); 
    border: 1px solid var(--ant-color-border);
    box-shadow: 0 8px 24px rgba(0,0,0,0.12);
    padding: 8px;
    z-index: 32px;
    min-height: 122px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.widgetsContainer{
    position: fixed;
    left: 320px;
    right: 82px; 
    bottom: 56px; 
    display: flex;
    z-index: 32;
    align-items: center;
    gap: 24px;
}
.widgetPanelScrollview{
    display: flex;
    flex: none;
    justify-content: center;
    width: 100%;
    overflow-x: auto;
}

@keyframes fadeScaleIn {
    0% {
        transform: scale(0.8) rotate(6deg) translateZ(0px);

    }
    50% {
        transform: scale(1.05) rotate(6deg) translateZ(0px);

    }
    100% {
        transform: scale(1) rotate(0deg) translateZ(0px);
    }
}
.draggableWidget{
    transition: ease-out .2s;
    border-radius: 16px;
    flex: none;
    background-color: var(--ant-color-bg-container);
    padding: 12px 20px;
    cursor: grab;
    margin: 0;
    animation: fadeScaleIn .4s cubic-bezier(0.09, 0.85, 0.74, 1.55);
}
.draggableWidget:hover{
    transform: rotate(6deg);
    box-shadow: 0 4px 12px rgba(0,0,0,0.12);

}
.draggableWidget:active{
    cursor: grabbing;
    transform: rotate(6deg) scale(1.2);
    box-shadow: 0 12px 24px rgba(0,0,0,0.12);
}
    .widgetPanelScrollview{
        justify-content: start;
    }
    .draggableWidget{
        margin: auto;
    }

.requestWidget{
    padding: 4px 16px 0px;
    text-align: center;
    width: 100%;
    transition: ease-out .2s;
    border-top: 1px solid var(--ant-color-border);
}

.draggingArea{
    position: fixed;
    top: 0;
    bottom: 0;
    color: var(--ant-color-primary);
    left: 242px;
    right: 0;
    z-index: 21;
    background-color: rgba(230, 213, 255, 0.6);
    transition: ease-in .2s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(12px);
    gap: 16px;
    border: 3px dashed var(--ant-color-primary-border);
}

@media screen and (max-width: 1600px){
    .reportContent{
        max-width: 90%;
    }
}
@media screen and (max-width: 1440px){
    .reportContent{
        max-width: 100%;
    }
}
@media screen and (max-width: 1023px){
    .personaInfo{
        width: 100%;
    }
    .reportContent{
        max-width: 100%;
        margin: 0;
    }
    .widgetsContainer{
        display: none;
    }
}

@media print {
    .reportContent{
        width: 595px;
        font-size: 12px;
    }
}
.reportCarousel{
    position: fixed !important;
   height: 100vh;
   width: 100vw;
   top: 0;
   left: 0;
   background-color: #000000;
   padding: 24px;

}
.reportCarousel *{
    outline: none;
}
.researchPresentation{
   
}

.presentationSlide{
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6em;
}
.coverSlide{
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6em;
}
.slideContent{
    border-radius: 24px;
    padding: 0px 72px 64px 72px;
    height: 100%;
    overflow: auto;
    position: relative;

}
.coverSlideContent{
    border-radius: 24px;
    padding: 64px 72px;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    position: relative;

}

.reportMarkdown :global(table tr:nth-child(2n)),
.reportMarkdown :global(.wmde-markdown table tr){
    background-color: transparent;
}
@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
.presentationPage > *{
    animation: fadeIn 0.5s ease-in-out;
}
.coverCopyright{
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    bottom: 96px;
}

