@keyframes scale-fade-in{
    0%{
        opacity: 0;
        transform: translate3d(0px, 0px, 0px) scale(0.5);
    }
    50%{
        opacity: 0;
        transform: translate3d(0px, 0px, 0px) scale(0.5);
    }
    75%{
        opacity: 0;
        transform: translate3d(0px, 0px, 0px) scale(0.5);
    }
    100%{
        opacity: 1;
        transform: translate3d(0px, 0px, 0px) scale(1);
    }
}
.appBar{
    padding: 16px 0 16px 0;
    top:0;
    position: sticky;
    z-index: 20;
    width: 100%;
}
.appBarOverlay{
    background-color: rgba(0,0,0,0.8);
    color: #FFFFFF;
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    padding: 12px 24px;
    z-index: 20;
}
.appBarAvatar{
    margin-top: 8px;
}
.appBarContent{
    display: grid;
    grid-template-columns: auto 1fr auto;
    grid-template-areas: 'avatar title actions';
}
.appBarOverlay .appBarContent{
    display: flex;
    justify-content: space-between;
}
.appBarText{
    grid-area: title;
    width: 100%;
    overflow: hidden;
}
.appBarTitle{
    margin: 0;
    width: 100%;
    margin-bottom: 0 !important;
}
.appBarActions{
    grid-area: actions;
}
@media screen and (min-width: 1024px){
    .appBarCta > *{
        border: none !important;
    }
}
@media screen and (max-width: 1023px){
    .appBar{
        padding: 16px 0 16px 0;
        top:0;
        position: sticky;
        z-index: 9;
        margin-top: 16px;
        z-index: 50;
    }
 
    .appBarAvatar{
        display: none;
    }
    .backActionTitle{
        display: none;
    }
    .responsiveSpace{
        align-items: center;
        gap: 24px;
        width: 100%;
        display: grid;
        grid-template-columns: auto 1fr;
    }
    .responsiveSpace h1{
        flex-grow: 1;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 100%;
        overflow: hidden;
        padding-right: 12px;
    }
    
    .appBarCta{
        position: fixed;
        bottom: 64px;
        right: 24px;
        z-index: 5;
        display: flex;
        left: 0;
        right: 0;
        justify-content: center;
    }
    .appBarCta > *{
        animation: ease-in-out .5s  scale-fade-in;
        height: 52px !important;
        display: flex;
        border-radius: 20px !important;
        align-items: center;
        padding: 8px 12px !important;
       
    }
    .appBarCta svg{
        width: 24px;
        height: 24px;
    }
    .appBarAction{
        display: flex;
    }
    .appBarAction > * > *{
        width: 42px;
        height: 42px !important;
        display: flex;
        align-items: center;
        padding: 12px !important;
        overflow: hidden;
        gap: 24px;
    }
    .appBarAction svg{
        width: 16px;
        height: 16px;
    }
    .appBar div:has(h1){
        overflow: hidden;

    }
    :global(.researchPage) .appBarCta{
        bottom: 24px;
      }
    
}
